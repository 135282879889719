<template>
  <modal class="scanCodePayModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">扫码付
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
        <div class="clearfix">
            <div class="left-box">
                <div class="money-info" v-if="SelectPlanDetail">
                    <div class="lable-txt">待支付金额：<b class="noney red">{{Number(SelectPlanDetail.Recharge_Money).toFixed(2)}}</b></div>
                </div>
                <div class="tr-li">
                    请扫码或输入付款码：
                    <div class="from-input" style="width:262px">
                        <input type="text" ref="input" data-model='payCode' v-model="payCode" @keyup.enter="confirm()" />
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
            </div>
        </div>
        <div class="tip-box">
            {{errorTitle}}
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-border" @click="select()">重新查询支付结果</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>

<script>
import {initKeyBoard,newGuid} from '/src/common' 

/**会员支付 */
export default {
    name:"scanCodePayModel",
    emits:["scanCodecloseModel","scanCodeconfirm"],
    props:['isShow','SelectPlanDetail','CrmBizID','ispaySuccess','Cardtype'],
    data(){
        return {
            payCode:"",//付款码
            /**变动时间戳 */
            ordertimestamps:'',
            /**错误返回 */
            errorTitle:'',
            /**会员用户 */
            userInfo:'',
            confirmType:0,
        }
    },
    mounted(){
       this.$nextTick(()=> {
            this.payCode=''
            this.errorTitle=''
            this.ordertimestamps=''
            initKeyBoard.call(this,this.$el,this.$refs.input);
            this.EmptyDatas(this.$refs.input);
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.payCode=''
                this.errorTitle=''
                this.ordertimestamps=''
                this.EmptyDatas();
            }
        },
        payCode(){
            if(this.payCode.length>18){
                this.payCode = this.payCode.slice(18)
            }
        },
    },
    methods:{
        selectValue(e){
            e.currentTarget.select()
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        hide(){
            this.$emit("scanCodecloseModel",this.ispaySuccess)
        },
        /**查询支付结果 */
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                    text: "查询支付结果中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Crm_BizID:this.CrmBizID, //结账单ID
                Operate_User:this.userInfo?.Login_Name, //操作员
                Operate_PosName:this.userInfo?.Site_Name, //站点名称
            }
            this.$httpAES.post("Bestech.CloudPos.RequeryCrmBizResult",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody?.PrintsInfo){
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(d.ResponseBody?.PrintsInfo);
                        },100);
                    }
                    if(this.Cardtype==0){
                        this.$alert('支付成功,开卡完成', '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.$emit("scanCodecloseModel",5)
                            }
                        });
                    }else if(this.Cardtype==1){
                        this.$alert('会员充值成功', '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.$emit("scanCodecloseModel",5)
                            }
                        });
                    }else if(this.Cardtype==2){
                        this.$alert('会员补卡成功', '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.$emit("scanCodecloseModel",5)
                            }
                        });
                    }
                }else{
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.$emit("scanCodecloseModel",0)
                            }
                        });
                    }else{
                        this.$message.error(d.ResponseHeader.ResultDesc);
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        /**确定和回车事件 */
        confirm(){
            if(this.ispaySuccess==0){
                this.confirmType=1
                if(!this.payCode){
                    this.$message.error('付款码不能为空');
                    return;
                }
                this.$emit("scanCodeconfirm",this.payCode)
            }
        },
    }
}
</script>

<style lang="sass">
@import "./scanCodePayModel.scss";
</style>